import { NextRouter } from 'next/router';

const routeMembership = {
  WAITING: '/error/waiting_membership',
  READY: '/error/not_membership_start',
  ACTIVE: '/community/new_post',
  OVERDUE: '/error/overdue_membership',
  EXPIRING: '/community/new_post',
  EXPIRED: '/error/not_membership',
  CANCELED: '/error/not_membership',
};

export const routeManager = (router: NextRouter, code: string) => {
  return router.push(routeMembership[code]);
};
