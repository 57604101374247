import { post, get, del, put } from './base';
var URL = '/membership';
export var PostMembershipAPI = function PostMembershipAPI(payload, config) {
  return post("".concat(URL, "/"), payload, config);
};
export var GetMembershipAPI = function GetMembershipAPI(config) {
  return get("".concat(URL, "/"), config);
};
export var GetMembershipMembersAPI = function GetMembershipMembersAPI(config) {
  return get("".concat(URL, "/members/?page_size=1000"), config);
};
export var GetMembershipPrivateSpaceAPI = function GetMembershipPrivateSpaceAPI(config) {
  return get("".concat(URL, "/spaces/"), config);
};
export var DeleteMembershipAPI = function DeleteMembershipAPI(config) {
  return del("".concat(URL, "/"), config);
};
export var DeleteMembershipMemberAPI = function DeleteMembershipMemberAPI(id, config) {
  return del("".concat(URL, "/members/").concat(id, "/"), config);
};
export var DeleteMembershipSpace = function DeleteMembershipSpace(id, config) {
  return del("".concat(URL, "/spaces/").concat(id, "/"), config);
}; // Invitation

export var PostMemberInvitationAPI = function PostMemberInvitationAPI(payload, config) {
  return post("".concat(URL, "/invitations/"), payload, config);
};
export var DeleteMemberInvitationAPI = function DeleteMemberInvitationAPI(id, config) {
  return del("".concat(URL, "/invitations/").concat(id, "/"), config);
};
export var PutMembershipNumberAPI = function PutMembershipNumberAPI(payload, config) {
  return put("".concat(URL, "/numbers/"), payload, config);
};
export var GetInviteCodeAPI = function GetInviteCodeAPI(id) {
  return get("".concat(URL, "/invitations/").concat(id, "/"));
};
export var PutInvitationCompleteAPI = function PutInvitationCompleteAPI(id, config) {
  return put("".concat(URL, "/invitations/").concat(id, "/"), {}, config);
}; // change payments

export var PutChangePaymentsAPI = function PutChangePaymentsAPI(config) {
  return put("".concat(URL, "/payment-method/"), {}, config);
};