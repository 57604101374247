import React, { useEffect, useState, useMemo } from 'react';
import Link from 'next/link';
import * as Sentry from '@sentry/nextjs';

import Router, { useRouter } from 'next/router';
import { routeManager } from 'utils/routeGuard';
import { useSelector } from 'store';
import { useCookies } from 'react-cookie';
import { GetUserHasNewNotification } from 'api/notifications';
import { makeAuthorizationConfig } from 'utils';
import HeaderLinks from './HeaderLinks';
import Drawer from './Drawer';

const userGuidePath = ['community', 'plus', 'myplace', 'notices'];

const Header: React.FC = () => {
  const router = useRouter();
  const [cookies] = useCookies();
  const { openDrawer, closeDrawer, DrawerPortal } = Drawer();
  const isLogin = useSelector(state => state.accounts.isLogin);
  const member = useSelector(state => state.membership.member);
  const hasMember = member !== undefined && member !== null;
  const isActive = member?.status === 'ACTIVE' || member?.status === 'EXPIRING';
  const [isMobile, setIsMobile] = useState(false);

  const [hasNewNotification, setHasNewNotification] = useState(false);

  Router.events.on('routeChangeComplete', () => closeDrawer());

  const clickForMember = async () => {
    if (!isLogin) {
      await router.push('/error/not_login');
    } else if (!hasMember) {
      await router.push('/error/not_membership');
    } else {
      await routeManager(router, member.status);
    }
  };

  const inMembership = useMemo(() => {
    const path = router.pathname.split('/')[1];
    return userGuidePath.some(slug => slug === path);
  }, [router.pathname]);

  useEffect(() => {
    if (!isActive) return;

    const config = makeAuthorizationConfig(cookies.access_token);
    GetUserHasNewNotification(config).then(
      ({ data }) => setHasNewNotification(data.is_exist),
      ({ response }) => {
        Sentry.setContext('response', response);
        Sentry.captureException(
          new Error('Failed to get user notification exist.'),
        );
      },
    );
  }, [cookies.access_token, isActive]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const viewForMember = () => (
    <nav id="snMenu">
      {inMembership ? (
        <div className="member">
          <a
            href="/notices"
            className="alarm"
            style={{ marginTop: -4, marginRight: 12 }}
          >
            <span>알림</span>
            {hasNewNotification && <i>새글있음</i>}
          </a>
        </div>
      ) : (
        <div style={{ marginTop: 6, marginRight: 12 }}>
          <a
            onClick={clickForMember}
            style={{
              marginTop: 2,
              cursor: 'pointer',
              padding: '8px 10px 6px 10px',
              border: '2px solid #323232',
              borderRadius: 24,
            }}
          >
            FOR<b> MEMBER</b>
          </a>
        </div>
      )}
      <button
        type="button"
        className="btn-menu"
        onClick={openDrawer}
        style={{ marginTop: 0, marginLeft: 0 }}
      >
        <span>메뉴열림</span>
      </button>
      <div id="local-drawer" />
    </nav>
  );

  const viewForNotMember = () => (
    <nav id="snMenu">
      <div>
        <a
          onClick={() =>
            window.open('http://localstitch-creatortown.com', '_blank')
          }
          style={{
            padding: '6px 12px',
            cursor: 'pointer',
            border: '2px solid #323232',
            borderRadius: 20,
            fontWeight: 'bold',
            marginRight: '5px',
            fontSize: isMobile ? '10px' : '14px',
          }}
        >
          코워킹 오피스 안내
        </a>
      </div>
      <div>
        <a
          onClick={() => router.push('/tour')}
          style={{
            padding: '6px 12px',
            cursor: 'pointer',
            border: '2px solid #323232',
            borderRadius: 20,
            fontWeight: 'bold',
            fontSize: isMobile ? '10px' : '14px',
          }}
        >
          투어•입주문의
        </a>
      </div>
      <button
        type="button"
        className="btn-menu"
        onClick={openDrawer}
        style={{
          marginTop: -2,
          marginLeft: isMobile ? '0' : '4px',
        }}
      >
        <span>메뉴열림</span>
      </button>
      <div id="local-drawer" />
    </nav>
  );

  return (
    <>
      <div id="skipNav">
        <ul>
          <li>
            <a href="#snContent">본문바로가기</a>
          </li>
          <li>
            <a href="#snMenu">주메뉴 바로가기</a>
          </li>
        </ul>
      </div>
      <header>
        <h1>
          <Link href="/">
            <a>
              <span>Local Stitch</span>
            </a>
          </Link>
        </h1>
        {hasMember ? viewForMember() : viewForNotMember()}
      </header>
      <DrawerPortal>
        <HeaderLinks closeDrawer={closeDrawer} />
      </DrawerPortal>
    </>
  );
};

export default Header;
