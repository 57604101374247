// eslint-disable-next-line import/extensions
import { get, post, del } from './base.js';
var URL = '/notifications';
export var PostFCMToken = function PostFCMToken(payload, config) {
  return post("".concat(URL, "/fcm-notifications/"), payload, config);
};
export var DeleteFCMToken = function DeleteFCMToken(token, config) {
  return del("".concat(URL, "/fcm-notifications/?fcm_token=").concat(token), config);
};
export var GetUserNotification = function GetUserNotification(page, config) {
  return get("".concat(URL, "/user-notifications/?page=").concat(page, "&page_size=10"), config);
};
export var GetUserHasNewNotification = function GetUserHasNewNotification(config) {
  return get("".concat(URL, "/user-notifications/has-new/"), config);
};