import React, { useState } from 'react';
import ModalTerms from 'components/Popup/ModalTerms';
import { GetTermsAPI } from 'api/site';
import { siteActions } from 'store/site';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import Modal from './Modal';
import { DrawerSideOptions } from '../../constants';

const Footer: React.FC = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const { openModal, ModalPortal, closeModal } = Modal();

  const clickCompanyNumber = () => {
    window.open('https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2798700849');
  };

  const modalOpen = async (type: string) => {
    const { data } = await GetTermsAPI(type);
    dispatch(siteActions.getTerm(data));

    openModal();
  };

  return (
    <>
      <footer>
        {open ? (
          <div id="ftrInfo" className="ftr-inner">
            <ul>
              <li onClick={() => modalOpen('terms')}>
                <a style={{ cursor: 'pointer' }}>이용약관</a>
              </li>
              <li onClick={() => modalOpen('locationterms')}>
                <a style={{ cursor: 'pointer' }}>위치기반서비스 이용약관</a>
              </li>
              <li onClick={() => modalOpen('privacy')}>
                <a style={{ cursor: 'pointer' }}>개인정보보호방침</a>
              </li>
            </ul>
            <div>
              <div className="info">
                <em>주식회사 로컬스티치</em>
                <span>대표 : 김수민</span>
                <span
                  onClick={clickCompanyNumber}
                  style={{ cursor: 'pointer' }}
                >
                  사업자등록정보 : 279-87-00849
                </span>
                <span>통신판매번호 : 2023-서울중구-1089</span>
              </div>
              <div className="addr-w">
                <address>
                  주소 : 서울특별시 중구 소공로 96(소공동)
                </address>
                <span>전화 : 02-322-8601</span>
                <span>개인정보보호책임 : 이주은</span>
              </div>
            </div>
          </div>
        ) : null}
        <p>
          LOCAL STITCH 2021. All Rights Reserved.&nbsp;
          <a onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
            {open ? 'LESS INFO' : 'MORE INFO'}
          </a>
        </p>
        <div style={{ display: 'flex', marginTop: 16 }}>
          {DrawerSideOptions.map((option, index) => {
            return (
              <React.Fragment key={index}>
                <span style={{ fontWeight: 'bold' }}>
                  {option.name === '채용' ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={option.href}
                    >
                      {option.name}
                    </a>
                  ) : (
                    <a onClick={() => router.push(option.href)}>
                      {option.name}
                    </a>
                  )}
                </span>
                {index < DrawerSideOptions.length - 1 && (
                  <span style={{ margin: '0 6px' }}>•</span>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div style={{ display: 'flex', marginTop: 16, fontWeight: 'bold' }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/localstitch/"
          >
            Instagram
          </a>
          <span style={{ margin: '0 6px' }}>|</span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://blog.naver.com/localstitchmaster"
          >
            Blog
          </a>
        </div>
      </footer>
      <ModalPortal>
        <ModalTerms closeModal={closeModal} />
      </ModalPortal>
    </>
  );
};

export default Footer;
