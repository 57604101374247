import React, { useEffect } from 'react';
import NextApp, { AppProps, AppContext } from 'next/app';
import Head from 'next/head';

// redux
import { useSelector, wrapper } from 'store';

// CSS
import 'styles/css/style.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'react-calendar/dist/Calendar.css';

import Header from 'components/common/Header';
import Footer from 'components/common/Footer';

import { AxiosResponse } from 'axios';
import {
  PostTokenVerifyAPI,
  PostTokenRefreshAPI,
  GetMyAccountAPI,
} from 'api/accounts';
import { GetMembershipAPI } from 'api/membership';
import {
  cookieStringToObject,
  makeAuthorizationConfig,
  setLocalStitchCookie,
} from 'utils';
import { accountActions } from 'store/accounts';
import _ from 'lodash';
import { useCookies } from 'react-cookie';
import { membershipActions } from 'store/membership';
import { useDispatch } from 'react-redux';
import { PostFCMToken } from 'api/notifications';
import { post } from 'api/base';

import ChannelService from '../components/common/ChannelTalk';

declare global {
  interface Window {
    IMP: any;
    naver_id_login: any;
    Kakao: any;
  }
}

const App = ({ Component, pageProps }: AppProps) => {
  const [cookies, setCookie] = useCookies();
  const dispatch = useDispatch();
  const token = useSelector(state => state.accounts.access);

  const isLogin = useSelector(state => state.accounts.isLogin);
  const user = useSelector(state => state.accounts.user);
  const member = useSelector(state => state.membership.member);

  useEffect(() => {
    ChannelService.loadScript();
    if (isLogin) {
      console.log(member != null ? member.status : null);
      ChannelService.boot({
        pluginKey: '2e114722-1741-4c5c-adeb-bdcb54037039',
        memberId: user.username,
        profile: {
          name: user.real_name,
          email: user.email,
          mobileNumber: user.phone_number,
          memberStatus: member != null ? member.status : null,
        },
      });
    } else {
      ChannelService.boot({
        pluginKey: '2e114722-1741-4c5c-adeb-bdcb54037039',
      });
    }
    return () => {
      ChannelService.shutdown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, member]);

  useEffect(() => {
    if (token !== '') {
      setLocalStitchCookie(setCookie, token, cookies.refresh_token);
    }
  }, [cookies.refresh_token, setCookie, token]);

  useEffect(() => {
    if (cookies.refresh_token && !cookies.access_token) {
      (async () => {
        const result = await post('/accounts/auth/token/refresh/', {
          refresh: cookies.refresh_token,
        });

        if (!result?.data?.access) return;

        const {
          data: { access },
        } = result;

        setLocalStitchCookie(setCookie, access, cookies.refresh_token);
        dispatch(accountActions.setLogin(true));
        dispatch(accountActions.setAccessToken(access));

        const config = makeAuthorizationConfig(access);
        const user: AxiosResponse = await GetMyAccountAPI(config);

        if (
          localStorage.getItem('fcmToken') &&
          localStorage.getItem('isAlarmOn')
        ) {
          const fcmToken = localStorage.getItem('fcmToken');
          const alarmStatus = localStorage.getItem('isAlarmOn');
          const payload = {
            fcm_token: fcmToken,
            is_agree_push: alarmStatus,
          };
          await PostFCMToken(payload, config);
        }

        dispatch(accountActions.setUserInfo(user.data));
      })();
    }
  }, [cookies.access_token, cookies.refresh_token, dispatch, setCookie]);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="initial-scale=1, minimum-scale=1, maximum-scale=1, width=device-width, target-densitydpi=medium-dpi"
        />
        <meta
          name="facebook-domain-verification"
          content="l3zmgrsgrv835wwm61xay6qbsb2so6"
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=GTM-5XK4X9W"
        />
        {/**/}
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'GTM-5XK4X9W');",
          }}
        />

        {/* <script
          dangerouslySetInnerHTML={{
            __html: `
          var address = window.location.href;
          if (address.includes('tour')) {
            gtag('event', 'conversion', {'send_to': 'AW-10775138695/UOkiCK2Eu4IDEIej_pEo'})
          }
            `,
          }}
        /> */}

        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-5XK4X9W');`,
          }}
        />
        {/* GTM-MCG7T42 */}

        <script
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '323381222568320');
            fbq('track', 'PageView');
          `,
          }}
        />

        <meta property="og:url" content="https://www.localstitch.kr" />
        <title>로컬스티치 - Local Stitch</title>
        <meta name="application-name" content="로컬스티치 - Local Stitch" />
        <meta
          name="msapplication-tooltip"
          content="로컬스티치 - Local Stitch"
        />
        <meta
          id="meta_og_title"
          property="og:title"
          content="로컬스티치 - Local Stitch"
        />
        <meta
          name="description"
          content="크리에이터의 Work&Life 커뮤니티, 로컬스티치"
        />
        <meta
          property="og:description"
          content="크리에이터의 Work&Life 커뮤니티, 로컬스티치"
        />
        <meta
          id="meta_og_image"
          property="og:image"
          content="https://www.localstitch.kr/images/og_image.jpeg"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta
          name="keywords"
          content="로컬스티치, Localstitch, Local Stitch, 로컬, 스티치, 연남장, 공유공간, 공간대여, 사무공간, 사무공간대여, 공유사무실, 사무실공유, 사무실대여, 공간공유, 코워킹, 코리빙, 스테이, 단기스테이, 워킹스페이스, Co-working, Co-living, 단기숙박, 공유관대여, 멤버십, 창작공간, 스타트업"
        />
        {process.env.SERVER_ENV !== 'production' && (
          <meta name="robots" content="noindex" />
        )}
        {process.env.SERVER_ENV === 'production' ? (
          <link rel="canonical" href="https://www.localstitch.kr" />
        ) : (
          <link rel="canonical" href="https://www.localstitch-er.com" />
        )}
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      </Head>
      <Header />
      <hr />
      <main id="snContent">
        <Component {...pageProps} />
      </main>
      <hr />
      <Footer />
      <noscript>
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          src="https://www.googletagmanager.com/ns.html?id="
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
        {/* GTM-MCG7T42 */}
      </noscript>
      <script
        type="text/javascript"
        src="https://code.jquery.com/jquery-1.12.4.min.js"
      />
      <script
        type="text/javascript"
        src="https://cdn.iamport.kr/js/iamport.payment-1.1.5.js"
      />
      <script
        type="text/javascript"
        src="https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js"
        charSet="utf-8"
      />
      <script src="https://developers.kakao.com/sdk/js/kakao.js" />
      <div id="root-modal" />
    </>
  );
};

App.getInitialProps = async (context: AppContext) => {
  const headerCookie = cookieStringToObject(context.ctx.req?.headers.cookie);
  const { store } = context.ctx;

  if (!_.isEmpty(headerCookie.access_token)) {
    const response: AxiosResponse = await PostTokenVerifyAPI({
      token: headerCookie.access_token,
    });

    if (!response || response.status >= 400) {
      const rsp: AxiosResponse = await PostTokenRefreshAPI({
        refresh: headerCookie.refresh_token,
      });

      if (rsp.status < 300) {
        headerCookie.access_token = rsp.data.access;
        store.dispatch(accountActions.setLogin(true));
      } else {
        store.dispatch(accountActions.setLogin(false));
      }
    } else {
      store.dispatch(accountActions.setLogin(true));
    }
  }

  if (!_.isEmpty(headerCookie.access_token)) {
    // set Access Token
    store.dispatch(accountActions.setAccessToken(headerCookie.access_token));

    // GET MEMBERSHIP
    const config = makeAuthorizationConfig(headerCookie.access_token);
    const member: AxiosResponse = await GetMembershipAPI(config);
    const user: AxiosResponse = await GetMyAccountAPI(config);

    if (member.status < 400) {
      store.dispatch(membershipActions.setMember(member.data));
    }

    store.dispatch(accountActions.setUserInfo(user.data));
  }

  const appInitialProps = await NextApp.getInitialProps(context);
  return { ...appInitialProps };
};

export default wrapper.withRedux(App);
