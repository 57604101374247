import { post, get, put } from './base';
var URL = '/accounts';
export var PostLoginAPI = function PostLoginAPI(payload) {
  return post("".concat(URL, "/auth/login/"), payload);
};
export var GetVerificationAPI = function GetVerificationAPI(tid) {
  return get("".concat(URL, "/id-verification-info/").concat(tid, "/"));
};
export var PostRegisterAPI = function PostRegisterAPI(payload) {
  return post("".concat(URL, "/auth/register/"), payload);
};
export var PostTokenVerifyAPI = function PostTokenVerifyAPI(payload) {
  return post("".concat(URL, "/auth/token/verify/"), payload);
};
export var PostTokenRefreshAPI = function PostTokenRefreshAPI(payload) {
  return post("".concat(URL, "/auth/token/refresh/"), payload);
};
export var PostPasswordResetAPI = function PostPasswordResetAPI(payload) {
  return post("".concat(URL, "/auth/password-reset/"), payload);
};
export var PostPasswordChangeAPI = function PostPasswordChangeAPI(payload, config) {
  return post("".concat(URL, "/auth/user/password/"), payload, config);
};
export var PostLogoutAPI = function PostLogoutAPI() {
  return post("".concat(URL, "/auth/logout/"));
}; // SNS

export var PostKakaoAPI = function PostKakaoAPI(payload) {
  return post("".concat(URL, "/auth/kakao/"), payload);
};
export var PostKakaoConnectAPI = function PostKakaoConnectAPI(payload, config) {
  return post("".concat(URL, "/auth/kakao/connect/"), payload, config);
};
export var PostNaverAPI = function PostNaverAPI(payload) {
  return post("".concat(URL, "/auth/naver/"), payload);
};
export var PostNaverConnectAPI = function PostNaverConnectAPI(payload) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return post("".concat(URL, "/auth/naver/connect/"), payload, config);
};
export var PostGoogleAPI = function PostGoogleAPI(payload) {
  return post("".concat(URL, "/auth/google/"), payload);
};
export var PostGoogleConnectAPI = function PostGoogleConnectAPI(payload) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return post("".concat(URL, "/auth/google/connect/"), payload, config);
};
export var PostFacebookAPI = function PostFacebookAPI(payload) {
  return post("".concat(URL, "/auth/facebook/"), payload);
};
export var PostFacebookConnectAPI = function PostFacebookConnectAPI(payload) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return post("".concat(URL, "/auth/facebook/connect/"), payload, config);
};
export var PostAppleAPI = function PostAppleAPI(payload) {
  return post("".concat(URL, "/auth/apple/"), payload);
};
export var PostAppleConnectAPI = function PostAppleConnectAPI(payload) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return post("".concat(URL, "/auth/apple/connect/"), payload, config);
};
export var PostDisconnectSocialAPI = function PostDisconnectSocialAPI(id, payload, config) {
  return post("".concat(URL, "/auth/socialaccounts/").concat(id, "/disconnect/"), payload, config);
}; // Profile

export var GetMyAccountAPI = function GetMyAccountAPI(config) {
  return get("".concat(URL, "/auth/user/"), config);
};
export var GetSocialAccountsAPI = function GetSocialAccountsAPI(config) {
  return get("".concat(URL, "/auth/socialaccounts/"), config);
};
export var PutKeywordsAPI = function PutKeywordsAPI(payload, config) {
  return put("".concat(URL, "/auth/user/keywords/"), payload, config);
};
export var PutUserProfileAPI = function PutUserProfileAPI(payload, config) {
  return put("".concat(URL, "/auth/user/"), payload, config);
};
export var PutChangePhoneNumberAPI = function PutChangePhoneNumberAPI(payload, config) {
  return put("".concat(URL, "/auth/user/phone-number/"), payload, config);
};
export var PutMarketingAgreedAPI = function PutMarketingAgreedAPI(payload, config) {
  return put("".concat(URL, "/auth/user/agreed-marketing/"), payload, config);
};
export var PutPushNotificationAgreedAPI = function PutPushNotificationAgreedAPI(payload, config) {
  return put("".concat(URL, "/auth/user/agreed-push-notification/"), payload, config);
};
export var PostDeactiveUserAPI = function PostDeactiveUserAPI(config) {
  return post("".concat(URL, "/auth/deactivate/"), {}, config);
};
export var PutProfileImageAPI = function PutProfileImageAPI(payload, config) {
  return put("".concat(URL, "/auth/user/profile-picture/"), payload, config);
};